const alertsModule = {
    state: {
        formData: {
            name: '',
            triggers: [],
            equipments: [],
            tasks: [],
            active: null
        }
    },
    mutations: {
        updateAlertsScenario(state, data) {
            state.formData.name = data.name;
            state.formData.triggers = data.triggers;
        },
        updateAlertsEquipments(state, data){
            state.formData.equipments = data.equipments;
        },
        updateAlertsTasks(state, data){
            state.formData.tasks = data.tasks;
        },
        updateAlerts(state, data){
            state.formData.name = data.name;
            state.formData.triggers = data.triggers;
            state.formData.equipments = data.equipments;
            state.formData.tasks = data.tasks;
            state.formData.active = data.active;
        },
        resetFormData(state) {
            state.formData.name = '';
            state.formData.triggers = [];
            state.formData.equipments = [];
            state.formData.tasks = [];
        }
    },
    getters: {
        formData: (state) => state.formData,
    },
}

export default alertsModule;